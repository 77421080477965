<template>
  <div class="view view--login">
    <div class="view-body">
      <Section>
        <Container>

          <div class="login-block">
            <img
              class="login-logo"
              alt="Le logo de OSS"
              src="../../assets/logo-oss-full.svg"
            />

            <h1>Identifiant oublié&nbsp;?</h1>

            <p>
              Veuillez indiquer votre adresse email, afin de vous envoyer votre
              identifiant.
            </p>

            <div class="form form--login">
              <form @submit.prevent="onSubmit()">
                <Input
                  id="email"
                  label="Email"
                  type="email"
                  placeholder="Ex : john.doe@gmail.co"
                />
                <Btn
                  btnType="submit"
                  text="Récupérer mon identifiant"
                  color="primary"
                  block
                  icon="user"
                />
              </form>
            </div>
            <p>
              <router-link :to="{ name: 'passwordForgottenStep1' }"
                >Mot de passe oublié&nbsp;?</router-link
              >
            </p>
            <p>
              <router-link :to="{ name: 'login' }">Se connecter</router-link>
            </p>
          </div>

        </Container>
      </Section>
    </div>
  </div>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import * as yup from 'yup'

export default {
  name: 'UsernameForgottenView',

  components: {
    Input,
    Btn,
    Section,
    Container,
  },

  data() {
    // Define a validation schema
    const validationSchema = yup.object().shape({
      email: yup.string().required().email(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(validationSchema)
    const onSubmit = this.formService.handleSubmit((values) => {
      this.sendRequest(values)
    })

    return {
      onSubmit,
      errors,
      isSubmitting,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.user.loggedIn
    },
  },
  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },
  methods: {
    sendRequest(values) {
      return this.fetchService
        .post('authentification/rappel_identifiants', values)
        .then(
          (response) => {
            this.formService.resetForm()
            this.emitter.emit('alert', {
              type: 'success',
              content: response.data,
            })
            this.$router.push({ name: 'login' })
          },
          (responseError) => {
            this.formService.handleApiError(responseError)
          },
        )
    },
  },
}
</script>
